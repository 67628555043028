import React, { useState, useEffect } from "react"
import { motion, useAnimation } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"
import { DateTime, IANAZone } from "luxon"

function ShopStatus({
    textStyling,
    dotIndicatorVisible,
    dotIndicatorFlashing,
    shopLocationTimezone,
    displayFormat,
    sundayStatus,
    mondayStatus,
    tuesdayStatus,
    wednesdayStatus,
    thursdayStatus,
    fridayStatus,
    saturdayStatus,
    sundayHours,
    mondayHours,
    tuesdayHours,
    wednesdayHours,
    thursdayHours,
    fridayHours,
    saturdayHours,
}) {
    const shopTimeZone = IANAZone.create(shopLocationTimezone)
    const [currentTime, setCurrentTime] = useState(
        DateTime.now().setZone(shopTimeZone)
    )
    const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ]

    const statusData = {
        Sunday: { status: sundayStatus, hours: sundayHours },
        Monday: { status: mondayStatus, hours: mondayHours },
        Tuesday: { status: tuesdayStatus, hours: tuesdayHours },
        Wednesday: { status: wednesdayStatus, hours: wednesdayHours },
        Thursday: { status: thursdayStatus, hours: thursdayHours },
        Friday: { status: fridayStatus, hours: fridayHours },
        Saturday: { status: saturdayStatus, hours: saturdayHours },
    }

    const getNextOpenDay = () => {
        let nextDayIndex = (currentTime.weekday % 7) + 1
        while (nextDayIndex !== currentTime.weekday % 7) {
            const nextDay = days[nextDayIndex]
            if (statusData[nextDay].status === "Open") {
                return `${nextDay} ${statusData[nextDay].hours}`
            }
            nextDayIndex = (nextDayIndex + 1) % 7
        }
        return "" // No open days found
    }

    const getStatusText = () => {
        const currentDay = days[currentTime.weekday % 7]
        const currentStatusData = statusData[currentDay]
        if (!currentStatusData || !currentStatusData.hours) return ""

        const { status, hours } = currentStatusData

        if (status === "Closed") {
            const nextOpenDayMessage = getNextOpenDay()

            if (nextOpenDayMessage) {
                const adjustedFormatOptions =
                    displayFormat === "12 hours"
                        ? { hour: "numeric", minute: "2-digit", hour12: true }
                        : { hour: "2-digit", minute: "2-digit" }

                const nextOpenDayFormatted = nextOpenDayMessage.replace(
                    /(\d{1,2}:\d{2}) - (\d{1,2}:\d{2})/g,
                    (match, openTime, closeTime) => {
                        return `${DateTime.fromFormat(openTime, "HH:mm")
                            .toLocaleString(adjustedFormatOptions)
                            .replace(",", "")} - ${DateTime.fromFormat(
                            closeTime,
                            "HH:mm"
                        )
                            .toLocaleString(adjustedFormatOptions)
                            .replace(",", "")}`
                    }
                )

                return `Closed. Open ${nextOpenDayFormatted}`
            } else {
                return "Closed. No upcoming open days."
            }
        }

        const [openTime, closeTime] = hours.split(" - ")
        const [openHour, openMinute] = openTime.split(":")
        const [closeHour, closeMinute] = closeTime.split(":")
        const openingTime = currentTime.set({
            hour: openHour,
            minute: openMinute,
        })
        const closingTime = currentTime.set({
            hour: closeHour,
            minute: closeMinute,
        })

        const formatOptions =
            displayFormat === "12 hours"
                ? { hour: "numeric", minute: "2-digit", hour12: true }
                : { hour: "2-digit", minute: "2-digit" }

        if (currentTime >= openingTime && currentTime <= closingTime) {
            return `Open today ${openingTime.toLocaleString(
                formatOptions
            )} - ${closingTime.toLocaleString(formatOptions)}`
        } else if (currentTime < openingTime) {
            return `Closed. Open today at ${openingTime.toLocaleString(
                formatOptions
            )}`
        } else {
            const nextOpenDayMessage = getNextOpenDay()

            if (nextOpenDayMessage) {
                const adjustedFormatOptions =
                    displayFormat === "12 hours"
                        ? { hour: "numeric", minute: "2-digit", hour12: true }
                        : { hour: "2-digit", minute: "2-digit" }

                const nextOpenDayFormatted = nextOpenDayMessage.replace(
                    /(\d{1,2}:\d{2}) - (\d{1,2}:\d{2})/g,
                    (match, openTime, closeTime) => {
                        return `${DateTime.fromFormat(openTime, "HH:mm")
                            .toLocaleString(adjustedFormatOptions)
                            .replace(",", "")} - ${DateTime.fromFormat(
                            closeTime,
                            "HH:mm"
                        )
                            .toLocaleString(adjustedFormatOptions)
                            .replace(",", "")}`
                    }
                )

                return `Closed. Open ${nextOpenDayFormatted}`
            } else {
                return "Closed. No upcoming open days."
            }
        }
    }

    const getStatusColor = () => {
        const currentDay = days[currentTime.weekday % 7]
        const currentStatusData = statusData[currentDay]

        if (currentStatusData && currentStatusData.status === "Open") {
            if (currentStatusData.hours) {
                const [openTime, closeTime] =
                    currentStatusData.hours.split(" - ")
                const [openHour, openMinute] = openTime.split(":")
                const [closeHour, closeMinute] = closeTime.split(":")
                const openingTime = currentTime.set({
                    hour: openHour,
                    minute: openMinute,
                })
                const closingTime = currentTime.set({
                    hour: closeHour,
                    minute: closeMinute,
                })

                if (currentTime >= openingTime && currentTime <= closingTime) {
                    return "green"
                }
            }
        }

        return "red"
    }

    const dotControl = useAnimation()

    useEffect(() => {
        if (dotIndicatorFlashing) {
            const pulse = async () => {
                await dotControl.start({ scale: 1.2 })
                await dotControl.start({ scale: 1 })
            }

            const interval = setInterval(pulse, 2000)

            return () => clearInterval(interval)
        } else {
            dotControl.stop()
        }
    }, [dotIndicatorFlashing, dotControl])

    const dotStyle = {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        backgroundColor: getStatusColor(),
        marginRight: "8px",
        display: dotIndicatorVisible ? "block" : "none",
    }

    const textStyle = {
        fontFamily: textStyling.font?.fontFamily,
        fontWeight: textStyling.font?.fontWeight,
        fontSize: textStyling.font?.fontSize,
        color: textStyling.color,
    }

    return (
        <motion.div>
            <div style={{ display: "flex", alignItems: "center" }}>
                <motion.div style={{ ...dotStyle }} animate={dotControl} />
                <p style={textStyle}>{getStatusText()}</p>
            </div>
        </motion.div>
    )
}

const timeZones = (Intl as any).supportedValuesOf("timeZone")

addPropertyControls(ShopStatus, {
    textStyling: {
        title: "Text Styling",
        type: ControlType.Object,
        controls: {
            font: {
                type: ControlType.Font,
                title: "Font",
                controls: "extended",
            },
            color: {
                type: ControlType.Color,
                title: "Color",
                defaultValue: "#000000",
            },
        },
    },
    dotIndicatorVisible: {
        type: ControlType.Boolean,
        title: "Dot Indicator Visible",
        defaultValue: true,
        enabledTitle: "On",
        disabledTitle: "Off",
    },
    dotIndicatorFlashing: {
        type: ControlType.Boolean,
        title: "Dot Indicator Flashing",
        defaultValue: false,
        enabledTitle: "On",
        disabledTitle: "Off",
    },
    shopLocationTimezone: {
        type: ControlType.Enum,
        title: "Shop Location Timezone",
        options: timeZones,
        defaultValue: "America/New_York",
    },
    displayFormat: {
        type: ControlType.Enum,
        title: "Display Format",
        options: ["12 hours", "24 hours"],
        defaultValue: "24 hours",
    },
    sundayStatus: {
        type: ControlType.Enum,
        title: "Sunday Status",
        options: ["Open", "Closed"],
        defaultValue: "Open",
    },
    mondayStatus: {
        type: ControlType.Enum,
        title: "Monday Status",
        options: ["Open", "Closed"],
        defaultValue: "Open",
    },
    tuesdayStatus: {
        type: ControlType.Enum,
        title: "Tuesday Status",
        options: ["Open", "Closed"],
        defaultValue: "Open",
    },
    wednesdayStatus: {
        type: ControlType.Enum,
        title: "Wednesday Status",
        options: ["Open", "Closed"],
        defaultValue: "Open",
    },
    thursdayStatus: {
        type: ControlType.Enum,
        title: "Thursday Status",
        options: ["Open", "Closed"],
        defaultValue: "Open",
    },
    fridayStatus: {
        type: ControlType.Enum,
        title: "Friday Status",
        options: ["Open", "Closed"],
        defaultValue: "Open",
    },
    saturdayStatus: {
        type: ControlType.Enum,
        title: "Saturday Status",
        options: ["Open", "Closed"],
        defaultValue: "Open",
    },
    sundayHours: {
        type: ControlType.String,
        title: "Sunday Opening Hours",
        defaultValue: "10:00 - 16:00",
        hidden(props) {
            return props.sundayStatus === "Closed"
        },
    },
    mondayHours: {
        type: ControlType.String,
        title: "Monday Opening Hours",
        defaultValue: "Closed",
        hidden(props) {
            return props.mondayStatus === "Closed"
        },
    },
    tuesdayHours: {
        type: ControlType.String,
        title: "Tuesday Opening Hours",
        defaultValue: "10:00 - 18:00",
        hidden(props) {
            return props.tuesdayStatus === "Closed"
        },
    },
    wednesdayHours: {
        type: ControlType.String,
        title: "Wednesday Opening Hours",
        defaultValue: "10:00 - 18:00",
        hidden(props) {
            return props.wednesdayStatus === "Closed"
        },
    },
    thursdayHours: {
        type: ControlType.String,
        title: "Thursday Opening Hours",
        defaultValue: "10:00 - 18:00",
        hidden(props) {
            return props.thursdayStatus === "Closed"
        },
    },
    fridayHours: {
        type: ControlType.String,
        title: "Friday Opening Hours",
        defaultValue: "10:00 - 18:00",
        hidden(props) {
            return props.fridayStatus === "Closed"
        },
    },
    saturdayHours: {
        type: ControlType.String,
        title: "Saturday Opening Hours",
        defaultValue: "10:00 - 18:00",
        hidden(props) {
            return props.saturdayStatus === "Closed"
        },
    },
})

export default ShopStatus
